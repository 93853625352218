import axios from './chat_axios'

// 问诊室明细-room_number
export const chatroomsDetail = (data) => axios({
    url: 'chatrooms/detail',
    method: 'get',
    params: data || {}
})

// 案件明细-   等用于案件详情
export const casesDetail = (id, data) => axios({
    url: 'patient-cases/' + id,
    method: 'get',
    params: data || {}
})

// 诊所商品-列表
export const clinicGoods = (data) => axios({
    url: 'clinic-goods',
    method: 'get',
    params: data || {}
})

// 案件收据(账单)-创建
export const patientCaseReceipts = (data) => axios({
    url: 'patient-case-receipts',
    method: 'post',
    data: data
})

//案件收據（賬單）-刪除
export const deletePatientCaseReceipts = (id) => axios({
    url: 'patient-case-receipts/' + id,
    method: 'delete',
})

//案件收據（賬單）-更新
export const updPatientCaseReceipts = (id, data) => axios({
    url: 'patient-case-receipts/' + id,
    method: 'put',
    params: data
})

// 案件收据(账单)-列表
export const getPatientCaseReceipts = (data) => axios({
    url: 'patient-case-receipts',
    method: 'get',
    params: data
})

// 上傳文件
export const uploadFile = (data, onUploadProgress) => axios({
    url: 'files/upload-file',
    method: 'post',
    data: data,
    onUploadProgress
})

// 修改案件
export const updCases = (id, data) => axios({
    url: 'patient-cases/' + id,
    method: 'put',
    params: data || {}
})

// 关闭问诊室
export const closeChatroom = (data) => axios({
    url: 'chatrooms/close',
    method: 'get',
    params: data || {}
})

// IM聊天消息列表
export const chatroomMessages = (data) => axios({
    url: 'chatroom-messages',
    method: 'get',
    params: data || {}
})

// 问诊室登陆
export const chatroomslogin = (data,params={}) => axios({
    url: 'chatrooms/login',
    method: 'post',
    data: data,
    params
})
