<template>
  <div class="attachment-page" :class="{ editing }" ref="attachmentMain">
    <section class="data-container">
      <h1>病人資料</h1>
      <div class="grid-container">
        <div>姓名</div>
        <div>{{ caseData.name }}</div>
        <div>性別</div>
        <div>{{ genderInText }}</div>
        <div>年齡</div>
        <div>{{ caseData.age }}</div>
        <div>出生日期</div>
        <div>{{ caseData.birthday }}</div>
        <div>電話號碼</div>
        <div>{{ caseData.phone }}</div>
        <div>備注</div>
        <div>{{ caseData.remark }}</div>
        <div>優惠碼</div>
        <div>{{ caseData.promoCode }}</div>
        <div>附件</div>
        <div class="folder-container">
          <div
            v-for="(attachmentFolder, index) in caseData.attachmentList"
            :key="index"
          >
            <button
              @click="activeFolder = activeFolder == index ? -1 : index"
              class="folder"
              :class="{ active: activeFolder === index }"
            >
              <span>
                <i class="icon-folder"></i>
                {{ attachmentFolder.folder }}
              </span>
              <i class="icon-rightarrow"></i>
            </button>
            <div class="folder-attachments">
              <div v-for="file in attachmentFolder.attachment" :key="file.id">
                <a :href="file.url" target="_blank">
                  {{ file.title }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="data-container">
      <h1>症狀</h1>
      <div class="grid-container">
        <div>{{ caseData.symptom }}</div>
      </div>
    </section>
    <section class="data-container">
      <h1>發病時間</h1>
      <div class="grid-container">
        <div>{{ caseData.diseaseTime }}</div>
      </div>
    </section>
    <section class="data-container">
      <h1>病歷</h1>
      <div class="grid-container">
        <div v-for="(item, index) in processedReplyList" :key="index">
          {{ item }}
        </div>
      </div>
    </section>
    <section class="data-container">
      <h1>診症資料 <span @click="openEditPage">填寫</span></h1>
      <div class="grid-container">
        <div>用戶症狀</div>
        <div>
          {{
            caseData.patientSymptoms ? caseData.patientSymptoms : "等待醫生填寫"
          }}
        </div>
        <div>醫生診斷</div>
        <div>
          {{
            caseData.doctorDiagnosis ? caseData.doctorDiagnosis : "等待醫生填寫"
          }}
        </div>
        <div>治療程序</div>
        <div>
          {{ caseData.treatmentPlan ? caseData.treatmentPlan : "等待醫生填寫" }}
        </div>
      </div>
    </section>
    <button class="end-btn" @click="closeChatroom">結束對話</button>
    <div class="edit-view" :class="{ editing }">
      <div class="back-area">
        <button class="back-btn" @click="editing = false">
          <i class="icon-leftarrow"></i>返回
        </button>
      </div>
      <div class="edit-area">
        <section>
          <h1>診症資料</h1>
          <div class="form-container">
            <div>病人症状</div>
            <el-input
              type="textarea"
              v-model="form.patientSymptoms"
              :autosize="true"
            ></el-input>
            <div>醫生診斷</div>
            <el-input
              type="textarea"
              v-model="form.doctorDiagnosis"
              :autosize="true"
            ></el-input>
            <div>治療程序</div>
            <el-input
              type="textarea"
              v-model="form.treatmentPlan"
              :autosize="true"
            ></el-input>
            <div>醫生附件</div>
            <div class="folder-container">
              <div
                v-for="(
                  attachmentFolder, index
                ) in form.doctorReportAttachmentList"
                :key="index"
              >
                <button
                  @click="activeFolder = activeFolder == index ? -1 : index"
                  class="folder"
                  :class="{ active: activeFolder === index }"
                >
                  <span>
                    <i class="icon-folder"></i>
                    {{ attachmentFolder.folder }}
                  </span>
                  <i class="icon-rightarrow"></i>
                </button>
                <div class="folder-attachments">
                  <div
                    v-for="file in attachmentFolder.attachment"
                    :key="file.id"
                  >
                    <a :href="file.url" target="_blank">
                      {{ file.title }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="uploader">
              <el-upload class="dashed" :http-request="doctorUploadFile">
                <span class="placeholder">上傳附件</span>
              </el-upload>
              <div class="notes">
                <span>請上傳相關文件</span>
                <span>如：病歷、相、情況等</span>
                <span>*每個檔案不能超過10mb</span>
              </div>
            </div>
            <div>備注</div>
            <el-input v-model="form.remark" type="textarea"></el-input>
          </div>
        </section>
        <!-- 这部分功能让医生在后台完成即可 -->
        <!-- <section>
                    <h1>項目</h1>
                    <div class="search-container">
                        <el-autocomplete
                            v-model="searchKey"
                            placeholder="輸入需要增加的收費項目"
                            :trigger-on-focus="false"
                            :fetch-suggestions="search"
                            @select="addReceipt"
                            clearable
                        ></el-autocomplete>
                    </div>
                    <div
                        class="item-container"
                        v-for="(item, index) in receipts"
                        :key="item.id"
                    >
                        <div class="title">{{ item.title }}</div>
                        <div class="quantity">
                            數量：
                            <button @click="decreaseQuantity(item, index)">
                                <i class="icon-minus"></i>
                            </button>
                            {{ item.quantity }}
                            <button @click="increaseQuantity(item)">
                                <i class="icon-plus"></i>
                            </button>
                        </div>
                        <div class="price">
                            HK {{ item.price * item.quantity }}
                        </div>
                    </div>
                </section> -->
      </div>
      <div class="save-area">
        <button class="save-btn" @click="saveCase">保存</button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  casesDetail,
  updCases,
  getPatientCaseReceipts,
  closeChatroom,
  clinicGoods,
  patientCaseReceipts,
  deletePatientCaseReceipts,
  updPatientCaseReceipts,
  uploadFile,
} from "@/api/chat.js";
import { mapState } from "vuex";
export default {
  props: {
    caseId: {
      type: Number,
      required: true,
    },
    roomId: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      caseData: {
        name: "",
        gender: "",
        age: "",
        birthday: "",
        phone: "",
        attachmentList: [],
        remark: "",
        doctorDiagnosis: "",
        treatmentPlan: "",
        patientSymptoms: "",
        promoCode: "",
        symptom: "",
        diseaseTime: "",
        materialSensitivity: "",
        useDrugs: "",
        clinicId: "",
        drugSensitivityReplyList: [],
      },
      form: {
        doctorDiagnosis: "",
        treatmentPlan: "",
        patientSymptoms: "",
        remark: "",
        doctorReportAttachmentList: [],
      },
      receipts: [],
      activeFolder: {},
      isFolderOpen: false,
      editing: false,
      searchKey: "",
    };
  },
  computed: {
    genderInText: function () {
      return this.caseData.gender == 1 ? "男" : "女";
    },
    ...mapState({
      isChatroomClosed: (state) => state.chat.isChatroomClosed,
      isVideoClosed: (state) => state.chat.isVideoClosed,
    }),
    processedReplyList() {
      let array = [];
      this.caseData.drugSensitivityReplyList.forEach((e) => {
        array.push(e.drugSensitivity.title);
        array.push(e.reply ? e.reply : "無");
      });
      return array;
    },
  },
  watch: {
    caseId: function () {
      if (this.caseId != -1) {
        this.getData();
      }
    },
  },
  methods: {
    doctorUploadFile(option) {
      let data = new FormData();
      data.append("file", option.file);
      uploadFile(data)
        .then((uploadRes) => {
          let data = {
            doctorReportAttachment: [uploadRes.data.filePath],
          };
          updCases(this.caseId, this.$underline(data)).then((updateRes) => {
            // this.setData(updateRes.data);
            this.$message({
              type: "success",
              message: "上傳附件成功",
            });
          });
        })
        .catch((err) => {
          console.error(err);
          this.$message({
            type: "error",
            message: err.message,
          });
        });
    },
    exitPager() {
      window.location.href = "about:blank";
      window.close();
      //   alert("已經選擇結束對話狀態，請關閉當前網頁");
    },
    closeChatroom() {
      if (this.type === "video" && this.isVideoClosed) {
        this.$message({
          type: "error",
          message: "問診室已關閉",
        });
        this.exitPager();
        return;
      } else if (this.type === "text" && this.isChatroomClosed) {
        this.$message({
          type: "error",
          message: "問診室已關閉",
        });
        this.exitPager();
        return;
      }

      // this.$confirm("一經確認,問診室將會永久關閉!").then(() => {
      //   closeChatroom({ id: this.roomId })
      //     .then((res) => {
      //       this.$message({
      //         type: "success",
      //         message: res.message,
      //       });
      //       this.$store.commit("chat/close");
      //       this.exitPager();
      //     })
      //     .catch((err) => {
      //       this.$message({
      //         type: "error",
      //         message: err.message,
      //       });
      //       this.exitPager();
      //     });
      // });
      this.$confirm("一經確認,問診室將會永久關閉!", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //點擊確認離開
          closeChatroom({ id: this.roomId })
            .then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.$store.commit("chat/close");
              this.exitPager();
            })
            .catch((err) => {
              this.$message({
                type: "error",
                message: err.message,
              });
              this.exitPager();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    uploadSuccess(res) {
      let filePath = res.data.filePath;
      let attachment = [filePath];
      updCases(this.caseId, { attachment }).then((res) => {
        this.caseData.attachmentList = res.data.attachmentList;
      });
    },
    increaseQuantity(item) {
      item.quantity++;
    },
    decreaseQuantity(item, index) {
      item.quantity--;
      if (item.quantity <= 0) {
        deletePatientCaseReceipts(item.id)
          .then(() => {
            this.$message({
              type: "success",
              message: "刪除成功",
            });
            this.receipts.splice(index, 1);
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: err.message,
            });
          });
      }
    },
    getData() {
      getPatientCaseReceipts({
        patient_case_id: this.caseId,
        pageSize: 100,
      }).then((res) => {
        this.receipts = [];
        this.$camel(res.data.dataList).forEach((e) => {
          this.receipts.push({
            id: e.id,
            price: e.price,
            quantity: e.quantity,
            title: e.title,
            typeId: e.typeId,
          });
        });
      });
      casesDetail(this.caseId).then((res) => {
        this.setData(res.data);
      });
    },
    setData(rawData) {
      let data = this.$camel(rawData);
      for (let key of Object.keys(this.caseData)) {
        if (Object.keys(data).includes(key)) {
          this.caseData[key] = data[key];
        }
      }
      for (let key of Object.keys(this.form)) {
        if (Object.keys(data).includes(key)) {
          this.form[key] = data[key];
        }
      }
    },
    openEditPage() {
      this.$refs.attachmentMain.scrollTop = 0;
      this.editing = true;
    },
    search(string, cb) {
      console.log(string);
      let data = {
        page: 1,
        title: string,
        clinic_id: this.caseData.clinicId,
      };
      clinicGoods(data)
        .then((res) => {
          let list = [];
          this.$camel(res.data.dataList).forEach((e) => {
            list.push({
              id: e.id,
              typeId: e.typeId,
              title: e.title,
              value: e.title,
              price: e.price,
            });
          });
          cb(list);
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.message,
          });
          console.error(err);
          cb([]);
        });
    },
    addReceipt(e) {
      let repeatFlag = false;
      this.receipts.forEach((i) => {
        if (i.title === e.title && i.typeId === e.typeId) repeatFlag = true;
      });
      if (repeatFlag) {
        this.$message({
          type: "error",
          message: "賬單中已有此項",
        });
        return;
      }
      let goods = [
        {
          typeId: e.typeId,
          title: e.title,
          price: e.price,
          quantity: 1,
        },
      ];
      let data = {
        patientCaseId: this.caseId,
        goods,
      };
      patientCaseReceipts(this.$underline(data))
        .then((res) => {
          /** 注意！！此處有兩個id，第一是商品id(e.id)，而另外一個
           *   則是商品作爲receipts的一個item時的id(也就是下面在算的東西)
           */
          let id;
          this.$camel(res.data).dataList.forEach((i) => {
            if (i.title === e.title && i.typeId === e.typeId) id = i.id;
          });
          this.receipts.push({
            id,
            title: e.title,
            quantity: 1,
            price: e.price,
            typeId: e.typeId,
          });
          this.$message({ type: "success", message: res.message });
        })
        .catch((err) => {
          this.$message({ type: "error", message: err.message });
          console.error(err);
        });
    },
    saveReceipt() {
      let successFlag = true;
      this.receipts.forEach((e) => {
        let data = {
          typeId: e.typeId,
          title: e.title,
          price: e.price,
          quantity: e.quantity,
        };
        updPatientCaseReceipts(e.id, this.$underline(data)).catch((err) => {
          console.error(err);
          successFlag = false;
        });
      });
      if (successFlag) {
        this.$message({
          type: "success",
          message: "賬單保存成功",
        });
      } else {
        this.$message({
          type: "error",
          message: "賬單保存失敗",
        });
      }
    },
    saveCase() {
      // 保存receipt的功能改为在后台操作。
      // this.saveReceipt();
      let data = JSON.parse(JSON.stringify(this.form));
      delete data.doctorReportAttachmentList;
      data = this.$underline(data);
      updCases(this.caseId, data)
        .then((res) => {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.setData(res.data);
          this.editing = false;
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.message,
          });
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.attachment-page {
  background-color: $white;
  overflow: auto;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;
  &.editing {
    overflow: hidden;
  }
}
.folder-attachments {
  display: none;
  div {
    width: 100%;
    word-break: break-all;
    overflow: hidden;
    margin-bottom: 8px;
    a,
    a:visited {
      color: $black;
    }
  }
}
.folder {
  width: 100%;
  background-color: transparent;
  color: $yellow-150;
  font-size: 16px;
  @include flexr-between-center;
  cursor: pointer;
  i {
    transition: all 0.1s ease;
    transform: rotateZ(90deg);
  }
  &.active {
    i {
      transform: rotateZ(-90deg);
    }
  }
  &.active + .folder-attachments {
    display: block;
  }
}
.uploader {
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 16px;
  align-items: center;
  padding: 0;
  margin: 16px 0;
  span.placeholder {
    @include flexc-center-center;
  }
  .notes {
    @include flexc-start-stretch;
    span {
      font-size: 14px;
    }
    span:first-of-type {
      font-size: 18px;
    }
  }
}
.end-btn {
  width: 80%;
  margin: 16px auto;
  background-color: $red;
  border-radius: 999px;
  padding: 10px;
  font-size: 16px;
}
h1 {
  @include flexr-between-center;
  span {
    text-decoration: underline;
    cursor: pointer;
  }
}
.edit-view {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  display: none;
  &.editing {
    @include flexc-start-stretch;
    .back-area {
      border-bottom: 1px solid $black;
      flex-shrink: 0;
    }
    .edit-area {
      flex-grow: 1;
      overflow: auto;
    }
    .save-area {
      flex-shrink: 0;
      @include flexr-between-center;
    }
    .back-btn {
      font-size: 24px;
      color: $black;
      padding: 0 16px;
      height: 79px;
      line-height: 79px;
      display: block;
      text-align: left;
      background-color: transparent;
    }
    .save-btn,
    .save-receipts-btn {
      width: 45%;
      margin: 8px auto;
      background-color: #016d77;
      border-radius: 999px;
      padding: 6px;
      font-size: 16px;
    }
    .save-btn {
      width: 80%;
      background-color: $green-300;
    }
  }
}
.edit-area {
  section {
    h1 {
      background-color: $green-300;
      color: white;
      font-size: 21px;
      font-weight: $bold;
      padding: 16px;
      margin: 0;
    }
    .form-container {
      padding: 16px;
      & > div {
        font-size: 16px;
        margin-bottom: 8px;
      }
      .el-input::v-deep {
        .el-input__inner {
          border: none;
          border-bottom: 1px solid $black;
          height: 30px;
          line-height: 30px;
          padding: 0;
        }
      }
      .el-textarea::v-deep {
        .el-textarea__inner {
          padding: 4px;
        }
      }
    }
    .item-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas: "title quantity" ". price";
      padding: 8px;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
    }
    .title {
      grid-area: title;
      font-size: 16px;
    }
    .quantity {
      grid-area: quantity;
      @include flexr-between-center;
      text-align: right;
      font-size: 13px;
      button {
        color: $black;
        background-color: transparent;
        text-align: center;
        width: 25px;
      }
    }
    .price {
      grid-area: price;
      text-align: right;
      font-weight: $semi-bold;
      color: $green-300;
    }
    .search-container {
      padding: 16px;
      .el-autocomplete::v-deep {
        width: 100%;
        .el-input {
          margin: 0;
        }
      }
    }
  }
}
</style>