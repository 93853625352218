<template>
    <div class="chat-page" :class="{ 'with-padding': isMsg }">
        <div class="header" v-if="isMsg">
            <img :src="doctorAvatar" alt="" />
        </div>
        <div class="msg-container" ref="chatMain">
            <div
                v-for="(msg, index) in messageList"
                :key="index"
                :class="[
                    msg.sender,
                    msg.type,
                    { loading: msg.loading },
                    { error: msg.error },
                ]"
                @click="resend(msg, index)"
            >
                <span v-if="msg.type === 'text'">
                    {{ msg.content }}
                </span>
                <div v-else-if="msg.type === 'image'">
                    <el-image
                        :src="msg.image"
                        :preview-src-list="[msg.image]"
                    ></el-image>
                </div>
                <div v-else @click="downloadFile(msg)">
                    <div class="content">
                        <span class="title">{{ msg.file.name }}</span>
                        <span class="size">{{ fileSize(msg.file.size) }}</span>
                    </div>
                    <div class="icon"><i class="icon-file"></i></div>
                    <div
                        class="progress-bar"
                        :style="{
                            '--progress': msg.uploadProgress,
                            display:
                                msg.uploadProgress === '100%'
                                    ? 'none'
                                    : 'block',
                        }"
                    ></div>
                </div>
            </div>
        </div>
        <div class="msg-input-area">
            <div class="input">
                <el-input
                    v-model="sendText"
                    type="textarea"
                    resize="none"
                    :autosize="{ minRows: 1, maxRows: 3 }"
                    @keydown.native="handleKeyCode($event)"
                ></el-input>
                <button class="upload" @click="clickUpload"></button>
                <input
                    ref="fileInput"
                    type="file"
                    id="file"
                    @change="changeFile"
                />
            </div>
            <button @click="handleTextSend" class="send"></button>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import chatMixin from "../chatroomMixins/chatMixin";
import { chatroomMessages } from "@/api/chat.js";
export default {
    mixins: [chatMixin],
    props: {
        doctorAvatar: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            userType: "doctor",
        };
    },
    computed: {
        ...mapState({
            tim: (state) => state.chat.tim,
            isReady: (state) => state.chat.isReady,
        }),
    },
    methods: {
        chatroomMessages,
    },
};
</script>

<style lang="scss" scoped>
@include chat-scss($type: doctor);
</style>