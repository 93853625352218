import Axios from 'axios'
import qs from 'qs'
import { messageTips } from '@/assets/js'
import store from '@/store'
import Vue from 'vue'

const axios = Axios.create({
  baseURL: process.env.NODE_ENV === "development" ? '/dev-api' : process.env.VUE_APP_REQUEST_BASE_URL,
  params: {}
})

const formDataRequest = ['patient-personals', 'patient-personals/upd', 'files/upload-file']
const formatConfig = config => {
  if (config.params === undefined) config.params = {}
  const { method, url } = config

  if (method !== 'get' && !formDataRequest.includes(url)) {
    config.data = qs.stringify(config.data)
  }
  if (config.params?.notLoading) {
    delete config.params.notLoading
  }
  const token = store.state.doctor.token
  if (token) config.params.access_token = token
  return config
}

// 請求攔截器
axios.interceptors.request.use(
  config => {
    let timestamp = new Date().getTime()
    config.timestamp = timestamp
    // 上一個修改的人寫了全局loading,改掉的話改動太大，參數判斷有沒有notLoading，有就不顯示loading
    if (!["clinic-goods"].includes(config.url) && !config.params?.notLoading)
      Vue.set(store.state.loadingList, timestamp, true)
    return formatConfig(config)
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
axios.interceptors.response.use(
  response => {
    let timestamp = response.config.timestamp
    Vue.delete(store.state.loadingList, timestamp)
    const code = parseInt(response.data.rtn) || parseInt(response.data.code)
    if (code !== 200) {
      if (code === 401) {
        // token异常
        messageTips('已退出登錄狀態，請重新登錄', 'error')
      }
      return Promise.reject(response.data)
    } else {
      return response.data
    }
  },
  error => {
    if (error.response && error.response.status === 500) {
      error.message = '服务器请求失败，请稍后再试！'
    }

    return Promise.reject(error)
  }
)

export default axios
